@import '../template/mixin_templates';
@import '../variables';

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    background-repeat: repeat;
    margin: 0;
    font-size: 16px;
    line-height: 1.28;
    font-family: 'ABeeZee', helvetica, arial, sans-serif;
}

@include titreBaseSize();

h2,
.h2 {
    border-bottom: 2px solid;
    display: block;
    font-weight: 400;
    margin: 5px 0;
}

iframe {
    max-width: 100%;
}

table {
    position: relative;
    table-layout: fixed;
}

.button, .addmsglo {
    border-radius: 5px 5px 5px 5px;
    font-size: 11px;
    font-weight: bold;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin:5px 3px;
}

/****************************************/
/*               HEADER                 */
/****************************************/

header {
    background-position: 0 0;
    background-repeat: repeat;
    position: relative;
    z-index: 1051;
}

#headercontent {
    height: 140px;
    margin: 0 auto;
    position: relative;
    width: 1020px;
    max-width: 100%;
}

.logo {
    //z-index: 12;
    left: 0;
    position: absolute;
    top: 5%;
    display: inline-block;
}

.logo img {
    max-height: 120px;
    max-width: 100%;
}

.slogan {
    position: absolute;
    right: 0;
    text-align: right;
    top: 45px;
}

.slogan_p {
    margin: 0;
}

/****************************************/
/*               CONTENEUR              */
/****************************************/

#wrapper, .accueil_diaporama_wrapper {
    margin: 0 auto;
    max-width: 1020px;
    position: relative;
    //z-index: 1;
    padding: 10px;
    width: 100%;
}

#wrapper {
    min-height: 50vh;
}

.wrapper img {
    max-width: 100%;
    height: auto !important;
    display: inline-block;
}

.container {
    display: block;
    margin: 0 auto;
}

.title_section {
    border-bottom: 2px solid;
    font-weight: 400;
    margin: 5px 0;
    width: auto;
    display: inline-block;
    min-width: 50%;
    padding: 3px 10px;
    a {
        text-decoration: none;
    }
    &:empty {
        display: none !important;
    }
}

/****************************************/
/*               MENU                   */
/****************************************/

.menu.navigation_p {
    position: relative;
}

header .menu.navigation_p {
    z-index: 10;
}

.wrapper-content .menu.navigation_p {
    z-index: 1050;
}

//Menu principal

#nav-principal {
    display: block;
    width: 100%;
    vertical-align: top;
    font-size: 14px;
    max-width: 1020px;
    margin: 0 auto;
    position: relative;
    //z-index: 100;
    > ul {
        > li {
            float: left;
            margin: 0 5px;
            position: relative;
            &:first-child{
                margin-left:0;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                height: 50%;
                width: 1px;
                top: 25%;
                right: -5px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            > ul {
                > li {
                    &:last-child {
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
    }
    ul {
        padding: 0 0;
        margin: 0;
        ul {
            visibility: hidden;
            opacity: 0;
            padding: 0;
            position: absolute;
            top: 100%;
            //z-index: 1000;
            min-width: 100%;
            z-index: 2;
            transition: opacity .2s linear, visibility 0s linear .2s;
            z-index: 1;
            li {
                display: block;
                float: none;
                left: 0;
                list-style: none outside none;
                opacity: 1;
                padding: 0;
                &:last-child {
                    border-radius: 0 0 0 0;
                }
            }
            ul {
                left: 100%;
                top: 0;
                li {
                    &:first-child {
                        border-top-right-radius: 5px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
        li:hover {
            > ul {
                visibility: visible;
                opacity: 1;
                transition-delay: 0s;
            }
        }
    }
    li {
        list-style: none;
        display: inline-block;
        position: relative;
        transition: background .2s linear, color .2s linear;
        a {
            display: block;
            padding: 11px 10px;
            text-decoration: none;
            transition: color .2s linear;
            white-space: nowrap;
            min-height: 41px;
            font-size: 15px;
            line-height: 15px;
        }
    }
}

//Menu secondaire

.wrapper-content .menu {
    border-bottom: 4px solid;
}

#nav-secondary {
    min-height: 60px;
    margin: 0 auto;
    position: relative;
    max-width: 1020px;
    width: 100%;
    &:empty {
        min-height: 0;
    }
    > ul {
        list-style: none outside none;
        padding: 0;
        position: relative;
        width: 100%;
        max-width: 1020px;
        //z-index: 10;
        margin: 0 auto;
        > li {
            display: inline-block;
            position: relative;
            vertical-align: top;
            height: 60px;
            float: left;
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 4px;
                transition: opacity .2s linear;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    ul {
        li:hover {
            > ul {
                visibility: visible;
                opacity: 1;
                transition-delay: 0s;
            }
        }
        ul {
            visibility: hidden;
            opacity: 0;
            height: auto;
            min-width: 100%;
            position: absolute;
            top: 100%;
            padding: 4px 0 0;
            //z-index: 1;
            transition: opacity .2s linear, visibility 0s linear .2s;
            li {
                line-height: 15px;
                &:hover {
                    display: block;
                }
            }
            a {
                padding: 10px;
            }
            ul {
                padding-top: 0;
                top: 0;
                left: 100%;
            }
        }
    }
    li {
        display: block;
        float: none;
        margin: 0;
        padding: 0;
        transition: background .2s linear, color .2s linear;
        position: relative;
    }
    a {
        display: block;
        line-height: 15px;
        padding: 21px 10px;
        text-decoration: none;
        white-space: nowrap;
        transition: color .2s linear;
        font-size: 17px;
    }
}

//Fil d'ariane
//Old
/*.arianne {
    position: relative;
    margin-bottom: 20px;
    border-bottom: 4px solid;
    ul {
        margin: 0;
        padding: 12px 0 5px 7px;
    }
    li {
        list-style: none;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        margin: 0 15px 0 0;
        font-size: 12px;
        &:not(:last-child)::after{
            content:'>';
            font-family: inherit;
            margin:0 0 0 15px;
            color:inherit;
        }
    }
    a {
        font-size: 12px;
        text-decoration: none;
    }
}*/

.arianne {
    position: relative;
    margin-bottom: 20px;
    ul {
        margin: 0;
        padding: 0;
        position: relative;
        font-size: 0;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -10px;
            width: 2px;
            height: 20px;
        }
    }
    li {
        position: relative;
        list-style: none;
        display: inline-block;
        margin: 0 10px;
        padding-top: 25px;
        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            color: inherit;
            top: 10px;
        }
        &::before {
            width: 100%;
            height: 2px;
            left: 0;
            padding: 0 10px;
            margin-left: -10px;
            box-sizing: content-box;
            z-index: 3;
        }
        &::after {
            width: 8px;
            height: 8px;
            left: 50%;
            border-radius: 50%;
            margin-left: -4px;
            margin-top: -3px;
            z-index: 2;
            transition: box-shadow .2s linear;
        }
        &:not(:last-child)::after {
            margin-left: -4px;
            margin-top: -3px;
        }
        &:last-child {
            &::before {
                width: 50%;
                padding-right: 0;
            }
            a {
                opacity: 1;
            }
        }
        &:hover {
            a {
                opacity: 1;
            }
        }
    }
    a {
        opacity: .5;
        padding: 5px 10px;
        font-size: 15px;
        position: relative;
        display: block;
        text-decoration: none;
        transition: opacity .2s linear;
    }
}

//Menu burger

.menu_burger {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    font-size: 15px;
    width: 3.2em;
    height: 3.2em;
    display: block;
    position: relative;
    float: right;
    top: 0;
    right: 0;
    transition: background .2s linear, color .2s linear;
}

.burger-icon-wing {
    transition: transform .1s linear;
    position: absolute;
    width: 1.1em;
    height: 0.2em;
    background-color: currentColor;
    &--m {
        transform-origin: 1.1em 0.1em;
        top: 50%;
        left: 0.5em;
        width: 2.2em;
        margin-top: -0.1em;
    }
    &--tl {
        transform-origin: 0.4em 0.1em;
        top: 0.8em;
        left: 0.5em;
    }
    &--tr {
        transform-origin: 0.7em 0.1em;
        top: 0.8em;
        right: 0.5em;
    }
    &--br {
        transform-origin: 0.7em 0.1em;
        bottom: 0.8em;
        right: 0.5em;
    }
    &--bl {
        transform-origin: 0.4em 0.1em;
        bottom: 0.8em;
        left: 0.5em;
    }
}

.menu_burger.menu_deploye {
    .burger-icon-wing {
        &--m {
            transform: scale(0, 1);
        }
        &--tl {
            transform: rotate(45deg) translate(0.3em, 0);
        }
        &--tr {
            transform: rotate(-45deg) translate(-0.3em, 0);
        }
        &--br {
            transform: rotate(45deg) translate(-0.3em, 0);
        }
        &--bl {
            transform: rotate(-45deg) translate(0.3em, 0);
        }
    }
}

/****************************************/
/*               GALERIE                */
/****************************************/

.template_album {
    .galerie {
        position: relative;

        .addcommentaires {
            padding: 0;
            height: 50px;
            width: 50px;
            text-align: center;
            font-size: 15px;
            line-height: 50px;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1) inset;
            position: absolute;
            top: 15px;
            right: 15px;
            border-radius: 50%;
            transition: opacity .2s linear, background-color .2s linear, border .2s linear, color .2s linear;
            border: 1px solid;
        }

        .commentP p {
            font-size: 15px;
            padding: 10px 15px;
            display: block;
            width: auto;
            margin-bottom: 0;
            margin-top: 0;
            transition: opacity .2s linear;
        }

        &:hover {
            .addcommentaires {
                opacity: 1;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &.template_album_2,
    &.template_album_3 {
        .commentP {
            top: auto;
            bottom: 0;
            transform: none;
        }
    }
    &.template_album_4,
    &.template_album_5 {
        .commentP p {
            display: block;
            padding: 0;
            width: 100%;
        }
    }
}

/****************************************/
/*               PRODUITS               */
/****************************************/

.order_product select{
    height: auto;
}

.mask {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

select {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid;
    display: inline-block;
    margin: 0 auto;
    width: auto;
    padding: 5px;
    font-size: 1.1rem;
    position: static;
    right: auto;
}

.template_boutique_all {
    .produits {
        position: relative;
        transition: transform .2s linear, z-index 0s linear .2s, box-shadow .2s linear;
        padding-bottom: 40px;
        //z-index: 1;
        &:hover {
            transform: scale(1.05);
            box-shadow: 1px 3px 5px 0 #656565;
            z-index: 6;
            transition-delay: 0s;
            img {
                transform: scale(1.1);
            }
            .mask, .zoom, .mask .zoom {
                opacity: 1;
            }
            .productImageWrap {
                .zoom::after {
                    opacity: .5;
                    transform: scale(1.1);
                }
            }
            .produit_etiquette::after {
                top: 100%;
                transition-delay: 0s;
                transform: scale(.99);
            }
        }
        img {
            width: 100%;
            height: auto;
            transition: transform .2s linear;
        }
        .nomprod {
            font-size: 20px;
            margin: 10px 0 10px;
            text-align: center;
            display: block;
            padding: 0 0 10px;
            border-bottom: 1px solid;
            a {
                font-size: inherit;
                color: inherit !important;
                text-decoration: none;
            }
        }
        .desc {
            padding: 5px 10px;
            font-size: 13px;
        }
        .prix {
            text-align: center;
            margin: 10px 0;
            .prixprod, .oldprix {
                float: none;
                display: inline-block;
                margin-bottom: 0;
            }
            .prixprod {
                font-size: 20px;
                font-weight: bold;
            }
            .oldprix {
                font-size: 15px;
                text-decoration: line-through;
                white-space: nowrap;
                color:red;
                font-weight: bold;
            }
        }
        .attr {
            text-align: center;
            position: static;
            margin: 10px 0;
            width: 100%;
            float: none;
            select {
                border-radius: 4px 4px 4px 4px;
                border: 1px solid;
                display: inline-block;
                margin: 0 auto;
                position: static;
                right: auto;
                height: auto;
            }
        }
        .addbasket {
            cursor: pointer;
        }
        .addbasket, .hors-stock {
            text-align: center;
            font-size: 16px;
            display: block;
            width: 100%;
            float: none;
            clear: both;
        }
        .hors-stock {
            float: none;
            width: auto;
        }
        .productImageWrap {
            overflow: hidden;
           .zoom {

                &::after {
                    content: '';
                    display: block;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: opacity .2s linear, transform .2s linear;
                }
            }
        }
        .productContentWrap {
            padding: 0 20px 0;
        }
        .mask {
            opacity: 1;
            transition: opacity .2s linear;
            text-align: center;
        }
        .zoom {
            background: transparent !important;
            opacity: 0;
            display: inline-block;
            position: absolute;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            border-radius: 0;
            border: none;
            text-align: center;
            &::before{
                display: none !important;
            }
            .button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform:translate(-50%, -50%);
                border:none !important;
                font-size: 15px;
                display: inline-block !important;
                margin: 0;
                text-align: center;
            }
        }
        .lien-mask {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .5;
        }
        .remise {
            left: auto;
            right: 0;
        }
        &.is_etiquette {
            .remise {
                top: 37px;
            }
        }
        .note-produit {
            text-align: center;
        }
    }
}

.template_boutique {
    &.template_boutique_1 .produits{
        @include elemTailleCol(4, 1.25, true);
        .desc{
            display: block !important;
        }
        .addbasket, .hors-stock {
            margin: 20px 0 0;
        }
        .produit_etiquette {
            position: relative;
        }
        &.no-produit_etiquette {
            .remise{
                top: 37px;
            }
        }
    }
    &.template_boutique_2 .produits {
        .desc{
            display: block !important;
        }
        .produit_etiquette {
            position: relative;
        }
        &.no-produit_etiquette {
            .remise{
                top: 37px;
            }
        }
    }
    &.template_boutique_3 .produits {
        padding-bottom: 10px;
        .nomprod {
            text-align: left;
            margin-bottom: 25px;
        }
        .attr, .desc, .prix {
            text-align: left;
            margin: 20px 0;
        }
        select {
            float: none;
        }
        .desc {
            padding: 0;
            color: inherit;
        }
        .addbasket {
            max-width: 250px;
            margin: 0 auto;
            position: static;
        }
        .productImageWrap {
            border-bottom: none;
        }
        .remise, &.is_etiquette .remise {
            padding: 0;
            height: 100%;
            left: 0;
            right: auto;
            top: 0;
            .inside-remise {
                top: auto;
                bottom: 0;
                left: 0;
                right: auto;
                height: 55px;
                border-radius:0 60px 0 0;
                padding: 18px 0 7px 7px;
                text-align: left;
            }
        }
        .note-produit {
            text-align: left;
        }
    }
    &.template_boutique_4 .produits {
        .productImageWrap {
            position: relative;
        }
        .remise {
            left: auto;
            right: 0;
            padding: 0;
            height: 0;
            top: 0;
            .inside-remise {
                top: 0;
                bottom: auto;
                right: 0;
                left: auto;
                height: 55px;
                border-radius:0 0 0 60px;
                padding: 13px 7px 12px 0;
                text-align: right;
            }
        }
        .attr {
            display: block;
            clear: both;
            select {
                width: 100%;
            }
        }
        .container-etiquette {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
    &.template_boutique_5 .produits {
        overflow: hidden;
        .remise {
            right: auto;
            left: 0;
            padding: 0;
            height: 100%;
            top: 0;
            .inside-remise {
                top: auto;
                bottom: 0;
                left: 0;
                right: auto;
                height: 55px;
                border-radius:0 60px 0 0;
                padding: 18px 0 7px 7px;
                text-align: left;
            }
        }
        .attr {
            display: block;
            clear: both;
        }
        .productImageWrap {
            border-bottom: none;
        }
        .note-produit + .prix {
            clear: both;
        }
    }
}

.template_boutique_accueil {
    .produits {
        &:hover {
            transform: scale(1);
        }
        .nomprod, .prix, .attr {
            margin: 10px 20px;
            display: block;
            width: auto;
        }
        .addbasket, .hors-stock {
            margin: 20px 20px 0;
            width: auto;
            display: block;
        }
        .produit_etiquette {
            position: relative;
        }
    }
    &.accueil_boutique_template_1 {
        @include produitAccueilTaille(0px, 3, 10px);
        .bx-controls-direction {
            a.bx-prev {
                left: 0;
            }
            a.bx-next {
                right: 0;
            }
        }
    }
}

.produit_etiquette {
    position: absolute;
    width: 100%;
    z-index: 5;
    &2, &3 {
        .h3 {
            padding: 8px 0;
        }
    }
    &2 {
        bottom: 0;
    }
    &::after {
        transition: top 0s linear .2s, transform .2s linear;
        border-width: 8px;
        margin-left: -4px;
        display: none;
    }
}

.remise, .remise-produit {
    z-index: 5;
    padding-top: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    display: block;
}

.produits .remise span {
    font-size: 18px;
}

.inside-remise {
    display: block;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    width: 76px;
    box-sizing: border-box;
    height: 55px;
    border-radius:0 0 0 60px;
    padding: 13px 7px 12px 0;
    text-align: right;
}

.galerie {
    &:hover {
        .mask {
            opacity: 0.8;
        }
    }
}

/****************************************/
/*           FICHES PRODUITS            */
/****************************************/

.zoomContainer {
    z-index: 1;
}

.cmonsite-ficheproduit-1 {
    #bloc-fil-title {
        h1 {
            display: none;
        }
    }
}

.template_fiche_produit {
    #fiche-produit {
        margin: 40px 0 0;
        .grande-image {
            &.is_etiquette .remise-produit {
                top: 42px;
            }
        }
        h1, .h1 {
            font-size: 20px;
        }
        .brand {
            font-size: 15px;
            border: none !important;
        }
        .remise-produit {
            display: block;
            font-weight: bold;
            padding: 14px 10px 5px 15px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            //z-index: 101;
            white-space: nowrap;
            .inside-remise{
                height: 55px;
                border-radius:3px 3px 3px 60px;
                padding: 13px 7px 12px 0;
                text-align: right;
            }
        }
        .sociaux {
            border-bottom: none;
            border-top-width: 1px;
            border-top-style: solid;
            padding: 8px 10px;
        }
        .wrap-description {
            padding: .5rem;
            > .row {
                width: auto;
                display: flex;
                margin-top: -.5rem;
                margin-bottom: 20px;
            }
            .block-fiche-titre-brand{
                align-items:center;
            }
            .block-fiche-brand{
                a{
                    text-align: center;
                    font-size: 16px;
                    text-decoration:underline;
                }
            }
            .h1, .h3, h1, h3 {
                margin: 0 0;
                width: 100%;
                text-align: center;
                padding: 15px 0;
            }
            h1, .h1 {
                font-size: 20px;
            }
            .description {
                margin-bottom: 28px;
                padding: 0;
            }
            .prix {
                border-top: medium none;
                float: none;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                width: auto;
                border-bottom: medium none;
                border-top: medium none;
                text-align: center;
                margin:0;
                padding:0;
                .prixprod {
                    border: 2px solid;
                    border-radius: 6px;
                    font-size: 32px;
                    font-weight: bold;
                    margin: 0;
                    padding: 5px;
                }
                .oldprix {
                    margin: 0 0 0 10px;
                    border: 2px solid;
                    border-radius: 6px;
                    font-size: 20px;
                    font-weight: bold;
                    padding: 5px;
                }
                .prixprod, .oldprix{
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .quantite {
                margin: 0;
                text-align: center;
            }
            select, .quantite {
                vertical-align: middle;
                display: inline-block;
            }
            .ajouter-panier {
                margin-bottom: 30px;
                margin-top:30px;
                &::after{
                    content:'';
                    display: block;
                    clear:both;
                }
                .quantite {
                    margin-right: 20px;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    text-align: center;
                    width: auto;
                }
            }
            .addbasket {
                font-size: 1.2rem;
                display: inline-block;
                vertical-align: middle;
                margin: 0 auto;
                .fa {
                    margin-right: 5px;
                }
            }
            .bloc-quantite{
                margin-top:15px;
            }
            label:first-child {
                margin-bottom: 0;
                margin-right: 10px;
            }
            label.radio_unique {
                border: none;
                &:hover {
                    cursor: pointer;
                }
            }

            label.radio_unique, label.radio_color {
                margin: 8px 5px;
                transition: opacity .2s linear;
                &.unavailable-option {
                    cursor: default;
                    &:hover {

                    }
                }
                &.unavailable-option span {
                    cursor: default;
                }
            }
            label.radio_color {
                border: 2px solid #fff;
                box-shadow: 0 0 3px 2px rgba(0, 0, 0, .1);
                transition: transform .2s linear, box-shadow .2s linear, opacity .2s linear;
                span {
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3) inset;
                    transition: box-shadow .2s linear;
                }
                &:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 3px 6px 3px rgba(0, 0, 0, .15);
                    span {
                        box-shadow: 0 0 0 0 rgba(0, 0, 0, .3) inset;
                    }
                }
                &.radio-color-checked, &.radio-color-checked {
                    border: 2px solid rgb(0, 0, 0);
                }
            }
            .notification_product {
                margin: 20px 0;
            }
            .sociaux {
                margin-left: -.5rem;
                margin-right: -.5rem;
            }
        }
        .remise-produit {
            width: 0;
            height: 0;
            padding: 0;
            background: transparent;
            color: inherit;
        }
        .declinaison {
            clear: both;
            margin-bottom: 0;
            margin-top: 15px;
        }
        .details-ficheprod{
            .btn-details{
                display: inline-block;
            }
        }
    }
    &.template_fiche_produit_1 #fiche-produit {
        .produit_etiquette{
            .h3{
                padding:15px 0;
                height: 55px;
            }
        }
        .grande-image{
            &.is_etiquette{
                .remise-produit{
                    top:55px;
                }
            }
        }
        .wrap-images .previews {
            .previous, .next {
                background-image: none;
                transition: left .2s linear, right .2s linear;
                width: 32px;
                height: 32px;
                i {
                    display: none;
                }
                &::after {
                    transition: color .2s linear, background .2s linear;
                    content: '';
                    display: block;
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    border: 2px solid;
                    text-align: center;
                    line-height: 28px;
                    text-indent: 0;
                    font-family: Fontawesome, sans-serif;
                    top: 50%;
                    margin-top: -16px;
                    font-size: 20px;
                }
            }
            .previous {
                &::after {
                    content: "\f177";
                }
            }
            .next {
                &::after {
                    content: "\f178";
                    right: 0;
                    left: auto;
                }
            }
        }
        .wrap-description{
            .ajouter-panier{
                width: auto;
                float: left;
            }
            .prix{
                width: auto;
                .prixprod{

                }
                .oldprix{

                }
            }
            .description{
                clear:both;
            }
            .pro-pannier{
                display:flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }
            .bloc-quantite{
                margin: 15px 0;
                order: 1;
                float: none;
                flex:1 1 18%;
            }
            .block-price{
                order: 2;
                width: auto;
                float: none;
                flex:1 1 45%;
                text-align: center;
            }
            .block-addbasket{
                order: 3;
                float: none;
                flex:1 1 37%;
                text-align: center;
            }
        }
    }
    &.template_fiche_produit_2 #fiche-produit {
        .grande-image {
            .remise-produit, &.is_etiquette .remise-produit, .triangle-ficheprod {
                top: 0;
                left: auto;
                right: 0;
            }
        }
        .wrap-description {
            .container-etiquette {
                margin: -.5rem -.5rem 0;
            }
            .container-etiquette + .row {
                margin-top: 0;
            }
            .note-produit {
                font-size: 15px;
            }
            .declinaison .declinaison-attribute-group,.declinaison .product_options-group {
                > label:first-child {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            .prix {
                width: 100%;
                float: none;
            }
            .description {
                padding: 0;
            }
            .noteGlobale {
                > .pull-left {
                    width: 100%;
                    .pull-left {
                        clear: both;
                        width: 100%;
                    }
                }
                .pull-right {
                    clear: both;
                    width: 100%;
                }
            }
        }
    }
    &.template_fiche_produit_3 #fiche-produit {
        .block-fiche-titre-brand{
            padding:10px 0;
        }
        .content-slider {
            .bx-pager {
                z-index: 4;
            }
        }
        .bx-controls {
            opacity: 1;
        }
        .bx-pager-item {
            opacity: 1;
        }
        .produit_etiquette {
            &::after {
                display: none;
            }
        }
        .wrap-description {
            > .row {
                margin-bottom: 0;
                margin-top: 0;
            }
            .declinaison-attribute-group,.product_options-group {
                > label:first-child {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            .bloc-quantite {
                float: none;
                width: 100%;
            }
            .prix {
                width: 100%;
                float: none;
                margin-top: 15px;
                .prixprod, .oldprix {
                    display: inline-block;
                    width: auto;
                }
            }
            .ajouter-panier{
                margin-top: 15px;
                padding-top: 0;
                margin-bottom: 10px;
            }
            .bloc-quantite{
                margin-top: 15px;
            }
        }
        .side-tabs {
            width: 100%;
            display: block;
            margin-bottom: 20px;
            float: none;
            a {
                margin-top: 0;
                display: inline-block;
                padding: 5px 15px;
            }
        }
        .in-onglet {
            width: 100%;
            display: block;
            margin-bottom: 20px;
            float: none;
        }
    }
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
      font-size: 1.2rem;
    }

    > .button + .button {
        margin-left: 15px;
    }

}


.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}

/****************************************/
/*                PANIER                */
/****************************************/

.template-panier {
    &.template-panier-3 {
        z-index: 9;
    }
    &.template-panier-4 {
        //z-index: 10;
        height: 60px;
        .connexion {
            margin-right: 0;
            a{
                padding: 0;
                line-height: 60px;
                margin: 0;
            }
        }
        .connexion, #panier {
            height: 60px;
            float: left;
            width: 45px;
        }
        .connexion, #header-panier {
            line-height: 60px;
        }
        #header-panier .fa-shopping-cart {
            display: block;
            line-height: 15px;
            padding: 22px 6px;
            text-decoration: none;
        }
        #header-panier .fa-shopping-cart, .connexion .fa-user {
            font-size: 23px;
        }
        .paniertoggle {
            position: absolute;
            padding: 0;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        .panier_quantite {
            right: 0;
            padding: 0 15px;
            line-height: initial;
        }
        #panier {
            border-radius:0;
            .fa-shopping-cart {
                margin-right: 0;
                width: 100%;
                text-align: center;
                line-height: 60px;
                padding: 0;
            }
        }
        #header-panier .count-live {
            left: 27px;
            top: 17px;
        }
    }
}

#nav-secondary .template-panier.template-panier-4 .connexion a{
    padding: 0;
    line-height: 65px;
    margin: 0;
}

/****************************************/
/*             FLUX PANIER              */
/****************************************/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    h4 {
        margin: 0;
    }

    .side-tabs .fa-shopping-cart {
        font-size: 14px;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

/****************************************/
/*             LIVRE D'OR               */
/****************************************/

.livre_dor {
    margin: 0;
    padding: 0 0 20px;
    .button {
        margin: 0 0 0 20px;
        float: right;
    }
    .buttonLivre::after {
        content: '';
        display: block;
        clear: both;
    }
    .list-messages {
        margin: 20px 0 0;
    }
}

.template_livreor {
    &.template_livreor_1 {
        .message_lo {
            padding: 40px 20px 45px;
            position: relative;
            h4, .h4 {
                bottom: 25px;
                font-family: arial, sans-serif;
                font-size: 10px;
                font-style: italic;
                margin: 0;
                position: absolute;
                right: auto;
                left: 20px;
            }
            .web {
                bottom: -2px;
                font-size: 12px;
                font-style: italic;
                right: 14px;
            }
            .note {
                font-family: arial, sans-serif;
                font-size: 16px;
                font-style: italic;
                font-weight: 700;
                height: 22px;
                margin: 0;
                position: absolute;
                right: 20px;
                text-align: right;
                top: 20px;
                width: 49px;
            }
            .message {
                font-size: 13px;
                margin: 0 0 20px;
                min-height: 50px;
                position: relative;
            }
        }
    }
    &.template_livreor_2 {
        .note {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

/****************************************/
/*               CONTACT                */
/****************************************/

.form-newsletter div:last-child {
    width: 100%;
}

.template_contact {
    h2 {
        margin-bottom: 25px;
    }
}

input.button[type="submit"] {
    border: none;
}

form, .template_contact form, #fiche-produit form {
    margin-top: 25px;
    .form-group {
        .qs_form {
            border: none;
        }
        input {
            background-image: none !important;
            &.hasDatepicker {
                max-width: 170px;
                margin: 0 5px;
                display: inline-block;
            }
            &[type="password"] {
                margin-top: 3px;
            }
        }
        input[type="text"]:not(.button):not([type="file"]), textarea, input[type="password"] {
            padding: 5px;
            font-size: 1.1rem;
            box-sizing: border-box;
            border-radius: 0;
            border-width: 1px;
            border-style: solid;
            box-shadow: none;
            &:focus, &:hover {
                border-style: solid;
            }
        }
        input[type="text"]:not(.button):not([type="file"]).hasDatepicker {
            border-width: 1px;
        }
        input[type="file"] {
            background: transparent !important;
            border: none !important;
        }
        textarea {
            min-height: 250px;
            border-width: 1px;
        }
        label:first-child {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .groupe-radio, .groupe-checkbox {
            text-align: left;
            position: relative;
            label {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    background: transparent;
                    border: 2px solid;
                    border-radius: 1px;
                    vertical-align: middle;
                    box-sizing: border-box;
                    margin: 8px 5px;
                }
            }
            input {
                position: absolute;
                opacity: 0;
            }
        }
        .groupe-radio label::before {
            border-radius: 50%;
        }
    }
}

.template_contact {
    &.template_contact_4 {
        .tmp4blocM .col.s12 {
            &.tmp4Map {
                max-height: none;
                height: 70%;
                height: calc(100% - 500px);
                &.tmp4FullMap {
                    height: 100%;
                }
            }
        }
        .tmp4blocC select {
            border: none;
        }
        form .form-group {
            &[data-type="date"] {
                input.hasDatepicker {
                    max-width: 100%-(100%/12*3);
                    margin: 0;
                }
            }
        }
    }
    &.template_contact_5 form .form-group {
        .groupe-radio, .groupe-checkbox {
            text-align: center;
        }
    }
}

#map-canvas {
    border: 1px solid;
}

/****************************************/
/*               BLOG                   */
/****************************************/

.article-description img {
    max-width: 100%;
    height: auto !important;
}
.apercu-article h2.titre-article-no-image, .apercu-article .h2.titre-article-no-image{
    background:transparent !important;
}

/****************************************/
/*               FOOTER               */
/****************************************/

footer {
    border-bottom: 1px solid;
    border-top: 4px solid;
    padding: 20px;
    width: 100%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    .mention {
        text-align: center;
        display: block;
        margin: 0;
        padding: 20px 0 0;
    }
    .music {
        text-align: center;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    #pub {
        text-align: center;
        margin: 0 0 30px;
    }

    .facebook {
        display: inline-block;

    }
}

/****************************************/
/*               SLIDERS                */
/****************************************/

.accueil_diaporama_template {
    .slide {
        .slide-text {
            opacity: 0;
            transition: opacity .2s linear;
            p {
                position: absolute;
                bottom: 40px;
                left: 15px;
                width: auto;
                display: inline-block;
                padding: 15px 20px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1) inset;
                max-width: calc(100% - 30px);
                font-size: 18px;
            }
            a.addcommentaires {
                padding: 0;
                height: 50px;
                width: 50px;
                text-align: center;
                font-size: 15px;
                line-height: 50px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1) inset;
                position: absolute;
                top: 15px;
                border-radius: 50%;
                right: 15px;
                transition: background-color .2s linear, border .2s linear, color .2s linear;
                border: 1px solid;
            }
            a.slider-see-more {
                display: inline-block;
                height: 40px;
                line-height: 40px;
                position: absolute;
                width: auto;
                padding: 0 15px;
                font-size: 20px;
                top: 80px;
                right: 15px;
            }
        }
        > li, > div {
            &:hover {
                .slide-text {
                    opacity: 1;
                }
            }
        }
    }
    &.accueil_diaporama_template_1,
    &.accueil_diaporama_template_3 {
        .bx-viewport, .slide {
            max-width: 100%;
        }
    }
    &.accueil_diaporama_template_2 {
        .slide .slide-text {
            p {
                left: 60px;
            }
            a.addcommentaires, a.slider-see-more {
                right: 60px;
            }
        }
    }
    &.accueil_diaporama_template_3 {
        .bx-controls-auto {
            display: none;
        }
        #bx-pager {
            font-size: 0;
            text-align: left;
            background: rgba(0, 0, 0, .02);
            padding: 5px;

            a {
                display: inline-block;
                width: 9%;
                min-width: 75px;
                margin: 0 .50%;
                transition: opacity .2s linear;
                &.active {
                    opacity: .1;
                }
            }
        }
    }
    &.accueil_diaporama_template_5 {
        .slide .slide-text {
            a.slider-see-more {
                font-size: 15px;
                padding: 0 10px;
            }
            p {
                right: 15px;
                text-align: center;
                padding: 5px 10px;
                bottom: 5px;
            }
        }
    }
}

.accueil_diaporama_full {
    margin: 0;
    padding: 0;
}

.accueil_diaporama_template,
.accueil_boutique_template_1,
.template_fiche_produit_3 {
    .bx-wrapper {
        margin-bottom: 0;
        .bx-pager {
            bottom: 20px;
            transition: bottom .2s linear;
            padding: 0;
            height: auto;
            font-size: 0;
        }
    }
    .bx-controls {
        opacity: 0;
        transition: opacity .2s linear;
        .bx-pager {
            z-index: 4;
        }
        a.bx-prev, a.bx-next {
            background-image: none;
            transition: left .2s linear, right .2s linear;
            z-index: 4;
            &::after {
                transition: color .2s linear, background .2s linear;
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                background: transparent;
                border: 2px solid;
                text-align: center;
                line-height: 28px;
                text-indent: 0;
                font-family: Fontawesome, sans-serif;
                top: 0;
            }
        }
        a.bx-prev {
            &::after {
                content: "\f177";
            }
        }
        a.bx-next {
            &::after {
                content: "\f178";
            }
        }
    }
    .bx-pager-item {
        opacity: 0;
        transition: opacity .2s linear;
        a.bx-pager-link {
            border: 2px solid;
            transition: background .2s linear, color .2s linear, border .2s linear;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            &.active, &:hover, &:focus {

            }
        }
    }

    &:hover {
        .bx-pager-item, .bx-controls {
            opacity: 1;
        }
        .bx-pager {
            bottom: 5px;
        }
        .bx-controls {
            a.bx-prev {
                left: 10px;
            }
            a.bx-next {
                right: 10px;
            }
        }
    }
}

/********************* A GARDER POUR LES MODELS ********************/

.wrapper .zoomPupImgWrapper > img, .wrapper .zoomWrapperImage > img {
    max-width: none !important;
}

/*-----------------------------
    Boutons profil
-------------------------------*/

#order .commandes td{
    padding:10px 0;
    border-top:1px solid rgba(128,128,128,.1);
    .button{
        margin: 0 5px 0 0 !important;
    }
}
#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media #{$xlarge-and-down}{
    #wrapper, .accueil_diaporama_wrapper, #nav-principal, #nav-secondary, #headercontent {
        max-width: 1020px;
    }
}

@media #{$large-and-down} {
    #wrapper, .accueil_diaporama_wrapper, #nav-principal, #nav-secondary, #headercontent {
        max-width: $medium-screen - 20;
    }

    //Template 1 boutique
    .template_boutique {
        &.template_boutique_1 .produits{
            @include elemTailleCol(3, 1.25, true);
        }
    }

    /*Suppression des blocs en dehors du contenu*/
    #block-flottant-search, #minisite-sidebar {
        display: none;
    }
}

@media #{$medium-and-down} {
    #wrapper, .accueil_diaporama_wrapper, #nav-principal, #nav-secondary, #headercontent {
        max-width: $small-screen - 20;
        width: 95%;
    }

    //Template 1 boutique
    .template_boutique {
        &.template_boutique_1 .produits{
            @include elemTailleCol(2, 1.25, true);
        }
    }

    /* Produits */

    .template_boutique_accueil {
        &.accueil_boutique_template_1 {
            @include produitAccueilTaille(0px, 2, 10px)
        }
    }
    /*Fin desactivation*/
    .logo {
        width: 100% !important;
        left: 0 !important;
        top: 10px !important;
        right: auto !important;
        bottom: auto !important;
        text-align: center;
        img {
            max-height: 65px !important;
        }
    }
    .slogan {
        left: 0 !important;
        text-align: center !important;
        bottom: 10px !important;
        top: auto !important;
        right: auto !important;
        font-size: 18px !important;
        width: 100% !important;
        //z-index: 9;
        height: auto !important;
    }

    //Galerie
    .template_album {
        .galerie {
            .addcommentaires, .commentP {
                opacity: 1;
            }
        }
        &.template_album_2 {
            .mask {
                height: 100%;
                opacity: 0;
            }
            .commentP {
                height: auto;
                &::before {
                    display: none;
                }
            }
            .galerie:hover {
                .mask {
                    opacity: .7;
                }
            }
        }
        &.template_album_3 {
            .mask {
                height: 100%;
                max-height: 100%;
                &.animMask {
                    display: none;
                }
            }
        }
    }

    //Panier
    .template-panier {
        &.template-panier-2 {
            top: 0;
            position: relative;
            float: right;
            width: auto;
            #panier {
                width: auto;
                &::after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
            #header-panier {
                float: right;
                position: static;
            }
        }
    }

    //Fiches produit
    .template_fiche_produit {
        &.template_fiche_produit_1 #fiche-produit {
            .wrap-description{
                .pro-pannier{
                    display: block;
                }
                .bloc-quantite{
                    margin:10px auto;
                }
                .prix {
                    float: none;
                    width: 100%;
                    margin: 10px auto;
                }
                .addbasket {
                    display: inline-block;
                    clear: both;
                }
                .ajouter-panier {
                    text-align: center;
                    margin:0 auto 20px;
                    width: 100%;
                }
            }
        }
        &.template_fiche_produit_2 #fiche-produit {
            .container-etiquette {
                width: auto;
                position: relative;
            }
        }
    }
}

@media #{$small-and-down}{
    /* Produits */

    .template_boutique_accueil {
        &.accueil_boutique_template_1 {
            @include produitAccueilTaille(0px, 1, 10px)
        }
    }

    //Template 1 boutique
    .template_boutique {
        &.template_boutique_1 .produits{
            @include elemTailleCol(1, 1.25, true);
        }
    }

    //Panier
    .template-panier {
        &.template-panier-3 {
            position: absolute;
            top: 100%;
            width: 100%;
            margin-top: 0;
            max-width: 100%;
            .connexion, #panier {
                width: 50%;
                float: left;
            }
            .connexion p {
                height: 48px;
                line-height: 48px;
            }
            #panier {
                height: 58px;
            }
            #header-panier {
                padding: 20px 10px;
            }
        }
    }

    //Logo / slogan
    #headercontent {
        height: auto;
    }
    .logo {
        position: static !important;
        margin: 10px 0;
    }
    .slogan_live_edit .slogan_p {
        position: static !important;
        text-align: center;
        margin: 0;
        padding: 0 0 10px;
    }


    @import '../../design/helper/responsive/mobile/mobile';

}

@media #{$large-and-up} {
    .menu_burger {
        display: none;
    }
}

@media #{$medium-and-down} {
    header .menu {
        position: relative;
        //z-index: 2;
    }
    header #blocContent {
        position: relative;
        //z-index: 1;
    }
    /* Nav mobile */
    .cmonsite-panier-2 #nav-principal {
        > .menu_burger {
            max-width: none;
            padding: 0;
            float: right;
        }
        > ul {
            max-width: 350px;
        }
    }
    #nav-principal, #nav-secondary {
        > ul {
            display: none;
        }
    }
    #nav-principal {
        > ul {
            top: 100%;
            > li > ul > li:last-child {
                border-radius: 0;
            }
            > li{
                margin: 0;
            }
        }
        ul {
            position: absolute;
            right: 0;
            width: 100%;
            max-width: 350px;
            li {
                display: block;
                width: 100%;
                float: none;
                &::after {
                    display: none;
                }
                a {
                    display: block;
                    padding: 10px;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                }
            }
            ul {
                position: relative;
                top: 0;
                left: 0;
                opacity: 1;
                visibility: visible;
                ul {
                    position: relative;
                    top: 0;
                    left: 0;
                    opacity: 1;
                }
            }
        }
    }
    .dropDown {
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        font-size: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: inherit !important;
    }
}


.template_fiche_produit #fiche-produit .triangle-ficheprod-second.triangle-ficheprod .remise-produit{
    display: none;
}